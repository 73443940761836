<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("groupSubjects.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("groupSubjects.list.group") }}
                </th>
                <th class="text-left">
                  {{ $t("groupSubjects.list.subject") }}
                </th>
<!--                <th class="text-left">
                  {{ $t("groupSubjects.list.name") }}
                </th>-->
                <th class="text-left">
                  {{ $t("groupSubjects.list.teacher") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in subjects" :key="item.id">
                <td>{{ item.groupName }}</td>
                <td>{{ item.subjectName }} {{ item.subgroupName }}</td>
<!--                <td>{{ item.name }}</td>-->
                <td>{{ item.teacherName }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="openMarks(item)"
                      variant="primary"
                  >
                    {{ $t("marks.title") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "teacherSubjects",
  components: {
    KTPortlet
  },
  data() {
    return {
      subjects: null
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      
      this.form.groupId = this.$route.params.id;      
    },
    reloadData() {
      ApiService.querySecured("groupSubjects/forTeacher", {
      }).then(({data}) => {
        this.subjects = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    openMarks(groupSubject) {
      if(groupSubject.groupSubjectSubGroupId != null){
        this.$router.push({name: "marks", params: {id: "sub-" + groupSubject.groupSubjectSubGroupId}});
      }else{
        this.$router.push({name: "marks", params: {id: groupSubject.groupSubjectId}});
      }
    }
  }
};
</script>
